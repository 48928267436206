import styled from 'styled-components'
import mq from 'styles/mq'

const Grid = styled.div`
  display: grid;
  gap: 1rem;

  ${mq.medium} {
    grid-template-columns: 1fr 1fr 1fr;
  }
`

export { Grid }
