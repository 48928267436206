import styled, { keyframes } from 'styled-components'
import mq from 'styles/mq'

const fadeOut = keyframes`
  from {
    opacity: 0;
    transform: translateY(-2rem);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`

export const Content = styled.section`
  max-width: 80rem;
  margin: 0 auto;
  background: #fff;
  padding: 2rem;

  ${mq.medium} {
    margin: 2rem auto;
    padding: 5rem 0 3rem;
  }
`

export const Main = styled.main`
  opacity: 0;
  animation: 0.7s ${fadeOut} 0s ease forwards;
`

export const Footer = styled.footer`
  text-align: center;
  margin: 3rem 0;

  @media print {
    display: none;
  }
`

export const Divider = styled.hr`
  margin: 3rem 0;

  @media print {
    display: none;
  }
`
