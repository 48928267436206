import React from 'react'
import { Mdx } from 'common/types'
import Layout from 'components/layout'
import { Grid } from './styled'
import ArticleCard from 'components/article-card'

type Props = {
  data: {
    allMdx: {
      edges: ReadonlyArray<{
        node: Mdx
      }>
    }
  }
}

const Travel = ({ data }: Props) => (
  <Layout>
    <Grid>
      {data.allMdx.edges.map(edge => (
        <ArticleCard key={edge.node.fields.slug} item={edge.node} />
      ))}
    </Grid>
  </Layout>
)

export default Travel
