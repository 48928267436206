import { graphql } from 'gatsby'
import Travel from 'modules/travel'

export default Travel

export const pageQuery = graphql`
  query {
    allMdx(
      filter: { frontmatter: { tags: { in: "photo" } } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMM YYYY")
            title
            thumbnail {
              childImageSharp {
                gatsbyImageData(width: 400)
              }
            }
          }
        }
      }
    }
  }
`
