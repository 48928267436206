import { css } from 'styled-components'

export const hoverEffect = css`
  position: relative;
  transition: transform opacity 0.3s cubic-bezier(0.2, 1, 0.8, 1);
  cursor: pointer;
  width: max-content;
  opacity: 0.8;

  &::before {
    transform-origin: 50% 100%;
    transition: clip-path 0.3s, transform 0.3s cubic-bezier(0.2, 1, 0.8, 1);
    clip-path: polygon(
      0% 0%,
      0% 100%,
      0 100%,
      0 0,
      100% 0,
      100% 100%,
      0 100%,
      0 100%,
      100% 100%,
      100% 0%
    );
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    background: currentColor;
    top: 105%;
    left: 0;
    pointer-events: none;
  }

  &:hover {
    transform: translate3d(0, -2px, 0);
    opacity: 1;

    &:before {
      transform: translate3d(0, 2px, 0) scale3d(1, 3, 1);
      clip-path: polygon(
        0% 0%,
        0% 100%,
        50% 100%,
        50% 0,
        50% 0,
        50% 100%,
        50% 100%,
        0 100%,
        100% 100%,
        100% 0%
      );
    }
  }
`
