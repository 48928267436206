import React from 'react'
import { getImage } from 'gatsby-plugin-image'
import { Mdx } from 'common/types'
import { Preview, Wrapper, Title } from './styled'

type Props = {
  item: Mdx
}

const ArticleCard = ({ item }: Props) => {
  const featuredImg = getImage(
    item.frontmatter.thumbnail?.childImageSharp?.gatsbyImageData
  )

  return (
    <Wrapper to={item.fields.slug}>
      <Preview image={featuredImg} />
      <Title>{item.frontmatter.title}</Title>
    </Wrapper>
  )
}

export default ArticleCard
