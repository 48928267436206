import React from 'react'
import { Link } from 'gatsby'
import { Links, Nav } from './styled'

const Navigation = () => {
  return (
    <Nav>
      <Links>
        <li>
          <Link to="/">Home</Link>
        </li>
        <li>
          <Link to="/blog">Blog</Link>
        </li>
        <li>
          <Link to="/speaking">Talks</Link>
        </li>
        <li>
          <Link to="/courses">Courses</Link>
        </li>
      </Links>
    </Nav>
  )
}

export default Navigation
