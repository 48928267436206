import { Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import styled from 'styled-components'
import mq from 'styles/mq'
import theme from 'styles/theme'

export const Title = styled.p`
  font-weight: bold;
  margin: 0.5rem 0;
`

export const Wrapper = styled(Link)`
  color: ${theme.color.textDark};

  &:hover {
    ${Title} {
      color: ${theme.color.accent.blue};
    }
  }
`

export const Preview = styled(GatsbyImage)`
  height: 20rem;

  ${mq.medium} {
    height: 16rem;
  }
`
