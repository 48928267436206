import styled from 'styled-components'
import { hoverEffect } from 'styles/mixins'
import mq from 'styles/mq'
import theme from 'styles/theme'

export const Nav = styled.nav`
  border-bottom: 0.1rem solid ${theme.color.border};
  padding: 1rem 0;
  text-align: center;
  margin-bottom: 4rem;

  ${mq.medium} {
    text-align: right;
  }
`

export const Links = styled.ul`
  list-style: none;
  display: inline-flex;
  gap: 2rem;
  padding: 0;
  margin: 0;

  a {
    color: ${theme.color.textDark};
    text-transform: uppercase;
    font-size: 1.4rem;
    font-weight: bold;
    ${hoverEffect};

    &:hover {
      color: ${theme.color.accent.blue};
    }
  }
`
