import React, { FC, ReactNode } from 'react'
import Navigation from 'components/navigation'
import GlobalStyles from 'styles/global'
import Fonts from 'styles/fonts'
import { Main, Content } from './styles'

type Props = {
  children: ReactNode
}

const Layout: FC<Props> = ({ children }) => (
  <>
    <GlobalStyles />
    <Fonts />
    <Content>
      <Navigation />
      <Main>{children}</Main>
    </Content>
  </>
)

export default Layout
